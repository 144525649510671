import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import React, { useEffect, useState } from 'react';
import { URL_ARG_ENTITY_TYPES } from 'client-lib';
import {
  TableHead,
  Table,
  Button,
  useMultiSelectTableItems,
} from '../../../../elements';
import CommonTableRow from './subComponents/CommonTableRow';
import {
  FlexCenterContainer,
  EmptyListHeader,
  MediumHeader,
  SmallHeader,
  FlexBetweenContainer,
} from '../sharedStyles';
import AudienceFilterSort from './subComponents/AudienceFilterSort';
import { getSortOptions, getOptInStatusText } from '../utils.ts';
import InfiniteScroll from '../../../InfiniteScroll/InfiniteScroll';
import THEMES from '../../../../styles/themes/app';

const SelectedAudienceTable = ({
  filters,
  loading,
  loadMoreRows,
  onFilter,
  onRemove,
  onSort,
  pageInfo,
  selectedAudience,
  sort,
  heightDifference,
  disableCheckboxes,
}) => {
  const [companyIds, setCompanyIds] = useState([]);
  const [contactIds, setContactIds] = useState([]);
  const [labelIds, setLabelIds] = useState([]);
  const [groupIds, setGroupIds] = useState([]);
  const [tagIds, setTagIds] = useState([]);

  const sortOptions = getSortOptions(i18n);

  const {
    handleCheckItem,
    selectedItems,
    handleSelectNone,
    allItemsSelected,
    shouldCheckItem,
  } = useMultiSelectTableItems({
    items: selectedAudience,
  });

  // Splits up selected ids by object type
  const handleCheck = (id, type) => {
    const newContacts = [...contactIds];
    const newCompanies = [...companyIds];
    const newGroups = [...groupIds];
    const newLabels = [...labelIds];
    const newTags = [...tagIds];

    switch (type) {
      case URL_ARG_ENTITY_TYPES.CONTACT:
        if (shouldCheckItem(id)) {
          newContacts.splice(
            newContacts.findIndex((c) => c === id),
            1
          );
        } else {
          newContacts.push(id);
        }
        break;
      case URL_ARG_ENTITY_TYPES.COMPANY:
        if (shouldCheckItem(id)) {
          newCompanies.splice(
            newCompanies.findIndex((c) => c === id),
            1
          );
        } else {
          newCompanies.push(id);
        }
        break;
      case URL_ARG_ENTITY_TYPES.GROUP:
        if (shouldCheckItem(id)) {
          newGroups.splice(
            newGroups.findIndex((g) => g === id),
            1
          );
        } else {
          newGroups.push(id);
        }
        break;
      case URL_ARG_ENTITY_TYPES.LABEL:
        if (shouldCheckItem(id)) {
          newLabels.splice(
            newLabels.findIndex((l) => l === id),
            1
          );
        } else {
          newLabels.push(id);
        }
        break;
      case URL_ARG_ENTITY_TYPES.LIST:
        if (shouldCheckItem(id)) {
          newTags.splice(
            newTags.findIndex((l) => l === id),
            1
          );
        } else {
          newTags.push(id);
        }
        break;
      default:
        break;
    }
    handleCheckItem(id);
    setContactIds(newContacts);
    setCompanyIds(newCompanies);
    setGroupIds(newGroups);
    setLabelIds(newLabels);
    setTagIds(newTags);
  };

  useEffect(() => {
    if (allItemsSelected) {
      const newContacts = [];
      const newCompanies = [];
      const newGroups = [];
      const newLabels = [];
      const newTags = [];

      selectedAudience.forEach((item) => {
        if (item.type === URL_ARG_ENTITY_TYPES.CONTACT) {
          newContacts.push(item.id);
        } else if (item.type === URL_ARG_ENTITY_TYPES.GROUP) {
          newGroups.push(item.id);
        } else if (item.type === URL_ARG_ENTITY_TYPES.LABEL) {
          newLabels.push(item.id);
        } else if (item.type === URL_ARG_ENTITY_TYPES.LIST) {
          newTags.push(item.id);
        } else newCompanies.push(item.id);
      });

      setContactIds(newContacts);
      setCompanyIds(newCompanies);
      setGroupIds(newGroups);
      setLabelIds(newLabels);
      setTagIds(newTags);
    }
  }, [allItemsSelected, selectedAudience]);

  // Adds selected Ids to list and then resets selection
  const handleRemove = () => {
    onRemove({
      companyIds,
      contactIds,
      groupIds,
      labelIds,
      tagIds,
      remove: true,
    });
    setCompanyIds([]);
    setContactIds([]);
    setGroupIds([]);
    setLabelIds([]);
    setTagIds([]);
    handleSelectNone();
  };

  return (
    <div>
      <FlexBetweenContainer>
        <AudienceFilterSort
          hasFilter
          filters={filters}
          sortBy={sort}
          sortOptions={sortOptions}
          handleSubmitFilter={onFilter}
          handleSubmitSort={onSort}
        />
        <Button
          onClick={() => handleRemove()}
          disabled={selectedItems.length === 0 || disableCheckboxes}
        >
          {i18n.t('broadcasts-broadcast-removeSelected', {
            defaultValue: 'Remove %{number} Selected',
            number: selectedItems.length,
          })}
        </Button>
      </FlexBetweenContainer>
      <Table>
        <TableHead>
          <tr>
            <MediumHeader>
              {i18n.t('settings-FinalMessage-customer', {
                defaultValue: 'contact',
              })}
            </MediumHeader>
            <SmallHeader>
              {i18n.t('settings-Groups-groups', { defaultValue: 'groups' })}
            </SmallHeader>
            <SmallHeader>
              {i18n.t('settings-Label-labels', { defaultValue: 'labels' })}
            </SmallHeader>
            <SmallHeader>
              {i18n.t('broadcasts-broadcast-optin', {
                defaultValue: 'Opt-in Status',
              })}
            </SmallHeader>
          </tr>
        </TableHead>
      </Table>
      <InfiniteScroll
        dataTestId="broadcast-recipient-list"
        bidirectionalScroll={false}
        height={`calc(100vh - ${heightDifference}px)`}
        scrollableList={selectedAudience}
        noRowsElement={
          <FlexCenterContainer>
            <EmptyListHeader dataTestId="create-broadcastList-empty">
              {i18n.t('slideouts-TargetedAudience-noResults', {
                defaultValue:
                  'No results were found for this type of audience.',
              })}
            </EmptyListHeader>
          </FlexCenterContainer>
        }
        renderRow={({ list, index }) => {
          const contact = list[index];
          return (
            <CommonTableRow
              key={contact.id}
              id={contact.id}
              checked={shouldCheckItem(contact.id)}
              name={contact.name}
              type={contact.type}
              company={contact.account?.name}
              groupIds={contact.groupIds}
              labels={contact.labels}
              color={contact.color}
              onCheck={() => handleCheck(contact.id, contact.type)}
              optInStatusText={getOptInStatusText(contact, i18n)}
              disabled={disableCheckboxes}
            />
          );
        }}
        hasNextPage={pageInfo.hasNextPage}
        hasPreviousPage={pageInfo.hasPreviousPage}
        listItemHeight={64}
        loadingHeight={64}
        loadingBackgroundColor={THEMES.BACKGROUND_PRIMARY}
        loadingBorderBottom
        loadMoreRows={loadMoreRows}
        loading={loading}
      />
    </div>
  );
};

SelectedAudienceTable.propTypes = {
  filters: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  loadMoreRows: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  pageInfo: PropTypes.shape({
    hasNextPage: PropTypes.bool,
    hasPreviousPage: PropTypes.bool,
    totalCount: PropTypes.number,
  }),
  selectedAudience: PropTypes.arrayOf(PropTypes.object).isRequired,
  sort: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  heightDifference: PropTypes.number,
  disableCheckboxes: PropTypes.bool,
};

SelectedAudienceTable.defaultProps = {
  pageInfo: {
    hasNextPage: false,
    hasPreviousPage: false,
    totalCount: 0,
  },
  heightDifference: 354,
  disableCheckboxes: false,
};

export default SelectedAudienceTable;
