import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import i18n from 'i18n-js';
import { Text, IconButton } from '../../../../../elements';
import BroadcastsReachableAudienceFlyout from '../../../../Announcements/DetailsFlyout/BroadcastsReachableAudienceFlyout';

const ReachableAudienceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TextIconWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const RecipientSubTitle = ({ reachableAudienceCount, announcementId }) => {
  const [isOpen, setIsOpen] = useState(false);

  return reachableAudienceCount > 0 ? (
    <ReachableAudienceWrapper>
      <TextIconWrapper onClick={() => setIsOpen(true)}>
        <Text>
          {reachableAudienceCount}{' '}
          {i18n.t('slideouts-GroupMessageRecipients-reachable')}
        </Text>
        <IconButton
          noOutline
          customStyle={() => 'padding-left: 4px; margin-top: 3px;'}
          contrast="highColor"
          size="sm"
          onClick={() => {}}
        >
          <i className="ri-eye-fill" />
        </IconButton>
      </TextIconWrapper>
      <BroadcastsReachableAudienceFlyout
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        announcementId={announcementId}
      />
    </ReachableAudienceWrapper>
  ) : (
    <>
      {!announcementId && (
        <Text>{i18n.t('slideouts-GroupMessageOverview-whoSendMessage')}</Text>
      )}
      {!!announcementId && (
        <Text>{i18n.t('broadcasts-overview-reachableAudienceEmpty')}</Text>
      )}
    </>
  );
};

RecipientSubTitle.propTypes = {
  reachableAudienceCount: PropTypes.number,
  announcementId: PropTypes.string,
  targetAllContacts: PropTypes.bool,
};

RecipientSubTitle.defaultProps = {
  targetAllContacts: false,
  reachableAudienceCount: 0,
  announcementId: '',
};

export default RecipientSubTitle;
